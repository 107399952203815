<template>
  <b-container class="py-5 px-4 px-md-5 container_monitors" fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-tabs>
          <!-- Main -->
          <b-tab title="Main" class="pt-5 pb-4 main" active>
            <b-container fluid class="px-lg-5">
              <!-- Login information -->
              <b-row class="mb-lg-3">
                <b-col cols="12">
                  <h5 class="fw-bold">Login information</h5>
                  <p>Operator setup</p>
                </b-col>
                <b-col cols="12" class="mb-md-2">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-radio></b-form-radio>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Add operator by invitation</p>
                        <p class="mb-0 fst-italic">The operator will receive an invitation email to set their login credentials.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col cols="12">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-radio></b-form-radio>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Manual account setup</p>
                        <p class="mb-0 fst-italic">You will specify the password on the new operator's behalf.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              <!-- Email -->
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4">
                  <b-form-group label="Email" label-for="email" class="text-start mb-2" label-class="fw-bold mb-1">
                    <b-form-input id="email" type="email" placeholder="user@user.com"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Password -->
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4">
                  <b-form-group label="Password" label-for="password" class="text-start mb-3" label-class="fw-bold mb-1">
                    <b-form-input id="password" type="password" placeholder="Password"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <!-- Contact information -->
              <b-row class="mt-md-4 mb-md-2">
                <b-col cols="12">
                  <h5 class="fw-bold">Contact information</h5>
                </b-col>
              </b-row>
              <!-- First name -->
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4">
                  <b-form-group label="First name" label-for="first-name" class="text-start mb-2" label-class="fw-bold mb-1">
                    <b-form-input id="first-name" type="text" placeholder="First name"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Last name -->
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4">
                  <b-form-group label="Last name" label-for="last-name" class="text-start mb-2" label-class="fw-bold mb-1">
                    <b-form-input id="last-name" type="text" placeholder="Last name"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- status -->
              <b-row>
                <b-col>
                  <div class="d-flex">
                    <label class="fw-bold">Status</label>
                    <div class="form-check form-switch ms-2">
                      <input class="form-check-input" type="checkbox" role="switch">
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          
          <!-- Member of -->
          <b-tab title="Member of" class="pt-5 pb-4 member-of">
            <b-container fluid class="px-lg-5">
              <b-row class="mb-3">
                <b-col cols="12">
                  <h5 class="fw-bold">This operator is a member of these groups</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4" class="border py-3">
                  <b-container class="p-0 px-md-3">
                    <b-row>
                      <b-container class="p-md-0">
                        <b-row>
                          <b-col cols="auto">
                            <b-form-checkbox></b-form-checkbox>
                          </b-col>
                          <b-col class="ps-0">
                            <b-container class="p-0">
                              <b-row>
                                <b-col cols="12">
                                  <vue-fontawesome icon="users" size="1" color="#000"></vue-fontawesome>
                                  <label class="ms-2">Administrators</label>
                                </b-col>
                                <b-col cols="12">
                                  <vue-fontawesome icon="user" size="1" color="#000"></vue-fontawesome>
                                  <label class="ms-2">Yehison Gómez</label>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="auto">
                            <b-form-checkbox></b-form-checkbox>
                          </b-col>
                          <b-col class="ps-0">
                            <b-container class="p-0">
                              <b-row>
                                <b-col cols="12">
                                  <label>Everyone</label>
                                </b-col>
                                <b-col cols="12">
                                  <vue-fontawesome icon="user" size="1" color="#000"></vue-fontawesome>
                                  <label class="ms-2">Yehison Gómez</label>
                                </b-col>
                                <b-col cols="12">
                                  <vue-fontawesome icon="user" size="1" color="#000"></vue-fontawesome>
                                  <label class="ms-2">Jose Llanes</label>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>

          <!-- Permissions -->
          <b-tab title="Permissions" class="pt-5 pb-4 main">
            <b-container fluid class="px-lg-5">
              <!-- Login information -->
              <b-row class="mb-lg-3">
                <b-col cols="12" class="mb-md-2">
                  <h5 class="fw-bold">System-wide permissions</h5>
                </b-col>
                <b-col cols="12" class="mb-md-2">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Basic operator</p>
                        <p class="mb-0 fst-italic">The default permissions for an operator to be able to view monitors and dashboards.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col cols="12">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Create alert definitions</p>
                        <p class="mb-0 fst-italic">Allow operators to create new alert definitions.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col cols="12">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Create alert definitions</p>
                        <p class="mb-0 fst-italic">Operator(s) that will be contacted in case of technical changes or issues related to the account.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              
              <!-- Monitor permissions -->
              <b-row class="mt-md-4 mt-lg-5 mb-md-2">
                <b-col cols="12">
                  <h5 class="fw-bold">Monitor permissions</h5>
                </b-col>
              </b-row>
              <!-- Monitor -->
              <b-row>
                <b-col cols="auto">
                  <b-form-group label="Monitor" label-for="monitor" class="mb-3" label-class="fw-bold">
                      <b-form-select v-model="form.monitor" :options="monitors" id="monitor" class="form-select">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-container>
                    <b-row>
                      <b-col cols="12" class="w-100 d-flex" style="justify-content: space-between;">
                        <label>View data</label>
                        <label>View settings</label>
                        <label>Edit settings</label>
                        <label>Create and delete</label>
                      </b-col>
                      <b-col cols="12">
                        <div class="d-flex">
                          <b-form-input id="range-monitor" type="range" class="w-100"></b-form-input>
                          <vue-fontawesome icon="close" size="1" color="#000" class="ms-3"></vue-fontawesome>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="auto" class="mb-2 mb-md-0">
        <b-button variant="primary" type="submit" class="px-5 w-100">Save</b-button>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button variant="secondary" type="submit" class="px-5 w-100">Cancel</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        monitor: null,
      },
      monitors: [
        { value: 'add_monitor', text: 'Add monitor' },
      ],
      load: false,
    }
  },
}
</script>
